import Swiper, { Scrollbar, Mousewheel } from "swiper";
class Quicklinks extends HTMLElement {
  constructor() {
    super();

    this.qlSlider = this.querySelector(".quicklinks-slider");
    let mobile = window.matchMedia("(max-width: 768px)");
    let collection = false;
    if (this.classList.contains("collection-quicklinks")) {
      collection = true;
    }

    const quicklinksSlider = new Swiper(this.qlSlider, {
      modules: [Scrollbar, Mousewheel],
      slidesPerView: "auto",
      spaceBetween: 12,
      mousewheel: {
        forceToAxis: true,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
      },
    });
  }
}

customElements.define("section-quicklinks", Quicklinks);
